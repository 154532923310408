import { graphql } from "gatsby"
import React from "react"
import DatoContentModules from "../DatoContentModules"
// import * as styles from "./DatoModularContentRef.module.scss"

const DatoModularContentRef = (props: Queries.DatoModularContentRefFragment) => {
  // console.debug("Rendering DatoModularContentRef", props)
  const modules = props.sharedModularContent?.modules
  return modules ? <DatoContentModules modules={modules} /> : null
}

export default DatoModularContentRef

export const query = graphql`
  fragment DatoModularContentRef on DatoCmsModularContentRef {
    sharedModularContent {
      ...DatoSharedModularContentModules
    }
  }
`
